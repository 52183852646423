/* eslint-disable @next/next/inline-script-id */
import Script from "next/script";
import { useEffect } from "react";

export default function ClaspoWidget(): JSX.Element {
  useEffect(() => {
    const script = document.querySelectorAll("script[data-nscript]");
    if (script.length) {
      script.forEach((item) => item.removeAttribute("data-nscript"));
    }
  }, []);

  return (
    <>
      <Script>
        {`
          !function (t, e, c, n) {
            var s = e.createElement(c);
            s.async = 1, s.src = 'https://scripts.claspo.io/scripts/' + n + '.js';
            var r = e.scripts[0];
            r.parentNode.insertBefore(s, r);
            var f = function () {
              f.c(arguments);
            };
            f.q = [];
            f.c = function () {
              f.q.push(arguments);
            };
            t['claspo'] = t['claspo'] || f;
          }(window, document, 'script', '5E59B984DD854E18B182691C96DD0CAB');
        `}
      </Script>
      <Script>{`claspo('init');`}</Script>
    </>
  );
}
